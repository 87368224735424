import React from "react";
import { graphql } from "gatsby";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLaptop, faCogs, faUsers } from "@fortawesome/free-solid-svg-icons";

import Layout from "../components/Layout";
import Head from "../components/Head";
import Heading from "../components/Heading";
import ContactForm from "../components/ContactForm";

import MobileSvg from "../images/Mobile_screen.svg";
import PaymentSvg from "../images/payment_gateway.svg";
import InvoicesSvg from "../images/invoices.svg";
import DashboardSvg from "../images/dashboard.svg";
// import Audit4 from "../images/Audit-4.svg";

import styles from "./index.module.scss";

export const query = graphql`
  query($language: String!) {
    allMarkdownRemark(
      filter: {
        fields: {
          category: { eq: "text" }
          slug: { eq: "index" }
          language: { eq: $language }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
            language
          }
          frontmatter {
            title
            date
            header {
              title
              subtitle
              description
            }
            sections {
              section {
                id
                title
                abovetitle
                subtitle
                description
              }
            }
            section_solutions {
              title
              subtitle
              point1_title
              point1_description
              point2_title
              point2_description
              point3_title
              point3_description
            }
          }
        }
      }
    }
  }
`;

const IndexPageTemplate = ({ data }) => {
  const pageContent = data.allMarkdownRemark.edges[0].node.frontmatter;
  const { header, sections, section_solutions } = pageContent;
  const section1 = sections.find((e) => e.section.id === "1").section;
  const section2 = sections.find((e) => e.section.id === "2").section;
  const section3 = sections.find((e) => e.section.id === "3").section;

  const heading = (
    <Heading className={styles.heading}>
      <div className={styles.banner}>
        <div className={styles.bannerInfo}>
          <h1>{header.title}</h1>
          <h2 className={styles.subTitle}>{header.subtitle}</h2>
          <h3>{header.description}</h3>
        </div>
        <div className={styles.bannerImg}>
          <img src={MobileSvg} alt={section2.title} />
        </div>
      </div>
    </Heading>
  );

  return (
    <Layout heading={heading}>
      <Head title={pageContent.title} />
      <div className={styles.mainContainer}>
        <div className={cx(styles.sectionContainer)}>
          <div className={styles.sectionPart}>
            <h6 className={styles.sectionTitle}>{section1.title}</h6>
            <h1 className={styles.sectionSubTitle}>{section1.subtitle}</h1>
            <p>{section1.description}</p>
          </div>
          <div className={styles.sectionPartImg}>
            <img src={PaymentSvg} alt={section1.title} />
          </div>
        </div>
        <div
          className={cx(
            styles.sectionContainer,
            styles.sectionBlue,
            styles.reverse
          )}
        >
          <div className={styles.sectionPartImg}>
            <img src={InvoicesSvg} alt={section2.title} />
          </div>
          <div className={styles.sectionPart}>
            <h2 className={styles.sectionTitle}>{section2.title}</h2>
            <p className={styles.sectionSubTitle}>{section2.subtitle}</p>
            <p>{section2.description}</p>
          </div>
        </div>
        <div className={cx(styles.sectionContainer)}>
          <div className={styles.sectionPart}>
            <h6 className={styles.sectionTitle}>{section3.title}</h6>
            <h1 className={styles.sectionSubTitle}>{section3.subtitle}</h1>
            <p>{section3.description}</p>
          </div>
          <div className={styles.sectionPartImg}>
            <img src={DashboardSvg} alt={section3.title} />
          </div>
        </div>
      </div>
      <div
        className={cx(
          styles.sectionContainer,
          styles.sectionBlue,
          styles.sectionSolutions
        )}
      >
        <div className={cx(styles.sectionPart, styles.sectionPartSolutions)}>
          <h2 className={styles.sectionTitle}>{section_solutions.title}</h2>
          <p>{section_solutions.subtitle}</p>
        </div>
        <div className={styles.solutions}>
          <div className={styles.solution}>
            <div className={styles.solutionIcon}>
              <FontAwesomeIcon icon={faLaptop} />
            </div>
            <div className={styles.solutionTitle}>
              {section_solutions.point1_title}
            </div>
            <div className={styles.solutionDescription}>
              {section_solutions.point1_description}
            </div>
          </div>
          <div className={styles.solution}>
            <div className={styles.solutionIcon}>
              <FontAwesomeIcon icon={faCogs} />
            </div>
            <div className={styles.solutionTitle}>
              {section_solutions.point2_title}
            </div>
            <div className={styles.solutionDescription}>
              {section_solutions.point2_description}
            </div>
          </div>
          <div className={styles.solution}>
            <div className={styles.solutionIcon}>
              <FontAwesomeIcon icon={faUsers} />
            </div>
            <div className={styles.solutionTitle}>
              {section_solutions.point3_title}
            </div>
            <div className={styles.solutionDescription}>
              {section_solutions.point3_description}
            </div>
          </div>
        </div>
        <ContactForm className={styles.contactForm} />
      </div>
      <div className={styles.emptySpace} />
    </Layout>
  );
};

export default React.memo(IndexPageTemplate);
