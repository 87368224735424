import React from "react";
import cx from "classnames";
import { withPrefix, graphql, useStaticQuery } from "gatsby";
import ReCAPTCHA from "react-google-recaptcha";

import { useLanguage } from "../LanguageSelect";
import styles from "./ContactForm.module.scss";

const useData = () =>
  useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fields: { slug: { eq: "contact-form" } } }) {
        edges {
          node {
            fields {
              slug
              language
            }
            frontmatter {
              title
              subTitle
              labels {
                name
                email
                country
                message
                send
              }
            }
          }
        }
      }
    }
  `);

const ContactForm = ({ className }) => {
  const language = useLanguage();
  const data = useData();

  const formatter = data.allMarkdownRemark.edges.filter(
    (element) => element.node.fields.language === language
  )[0].node.frontmatter;

  return (
    <div className={cx(styles.contactForm, className)}>
      <div className={styles.title}>{formatter.title}</div>
      <div className={styles.description}>{formatter.subTitle}</div>
      <form
        name="Contact Form"
        method="POST"
        data-netlify-recaptcha="true"
        data-netlify="true"
        action={withPrefix(`${language}/contact-thanks`)}
      >
        <input type="hidden" name="form-name" value="Contact Form" />
        <div className={styles.field}>
          <input type="name" name="name" placeholder={formatter.labels.name} />
        </div>
        <div className={styles.field}>
          <input
            type="email"
            name="email"
            placeholder={formatter.labels.email}
          />
        </div>
        <div className={styles.field}>
          <input
            type="country"
            name="country"
            placeholder={formatter.labels.country}
          />
        </div>
        <div className={styles.field}>
          <textarea name="message" placeholder={formatter.labels.message} />
        </div>
        <ReCAPTCHA sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY} />
        <button type="submit" className={styles.submitBtn}>
          {formatter.labels.send}
        </button>
      </form>
    </div>
  );
};

export default React.memo(ContactForm);
